import React, { useEffect, useContext } from 'react'
import { AnimatePresence, motion } from "framer-motion"
import { Tabs, Tab, Table } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faArrowLeft
} from "@fortawesome/free-solid-svg-icons";
import QRCode from "react-qr-code";
import { NavbarPresenter } from './components/Navbar.js'
import NotyfContext from "../../contexts/NotyfContext.js";
import { TextWithLineBreaks } from "../../components/Functions.js"
import { useAppState, getData } from "./context/AppContext.js"
import { useGlobalState } from '../../hooks/useCustomization.js';
import { domainConfig } from "../../assets/config.js"
import { PageLoader } from "../../components/Elements"

const MemoryGameAdmin = () => {
    const { dataApplication, handleExit, blockScreen } = useGlobalState();
    const { appState, updatePlayers } = useAppState();

    useEffect(() => {
        if (appState.isConnected) {
            getData()
            const intervalId = setInterval(() => {
                getData()
            }, 2000);
            return () => clearInterval(intervalId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updatePlayers, appState.isConnected]);

    if (appState.history) {
        if (!blockScreen) {
            return (
                <>
                    <motion.nav
                        className="position-fixed w-100 px-3 justify-content-between navbar navbar-expand"
                        key={'appsNavbar'}
                        initial={{ y: -100 }}
                        animate={{ y: 0 }}
                        transition={{ duration: 0.5 }}
                        exit={{ y: -100 }}>
                        <NavbarPresenter />
                    </motion.nav>
                    <motion.div className='presenter-box container' key="mainDiv" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                        <div className="box">
                            <div className="logoBox">
                                <div>
                                    <div className="backButton" onClick={() => handleExit()}><FontAwesomeIcon icon={faArrowLeft} size={'lg'} /></div>
                                </div>
                                <div className="logoBoxChildMiddle">
                                    <div className="pb-3">
                                        <h5 className="mb-1"><TextWithLineBreaks text={appState.titulo}></TextWithLineBreaks></h5>
                                        <span className="badge primaryColor primaryColorText mb-5 fw-normal">{appState.nomeAplicativo}</span>
                                    </div>
                                </div>
                                <div className="logoBoxHideButton">
                                    <div className="backButton"></div>
                                </div>
                            </div>
                            <Tabs
                                defaultActiveKey={'k1'}
                                transition={true}
                                id="noanim-tab-example"
                                className="mb-4"
                            >
                                <Tab eventKey="k1" title={`Configurações`} key={"k1"}>
                                    <div className="row mx-0">
                                        <p className="my-5 text-center opacity-75">Nenhuma configuração disponível</p>
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>
                    </motion.div>
                </>
            )
        } else {
            return (
                <div className='default-box'>
                    <div className={`box-full`}>
                        <img src={domainConfig.imageServer + "/evento/" + dataApplication.evento_id + "/" + dataApplication.customizacao.logo} alt="Logo" className="App-logo"></img>
                        <p>Essa tela só está disponível<br></br>para dispositivos maiores que 775px</p>
                    </div>
                </div>
            )
        }
    } else {
        return (
            <motion.div className='default-box' key={'loaderDiv'}>
                <PageLoader color={dataApplication.customizacao.primaryColor} width={75}></PageLoader>
            </motion.div>
        )
    }
}

export const MemoryGameRankingModal = () => {
    const { dataApplication } = useGlobalState();
    const { appState, setShowPresentation } = useAppState();
    const notyf = useContext(NotyfContext);

    useEffect(() => {
        notyf.open({
            type: "success",
            message: "Para fechar o modal basta utilizar o 'ESC'",
            ripple: true,
            duration: 5000,
            dismissible: true,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape' || event.keyCode === 27) { // Check if the "End" key was pressed
                setShowPresentation(false);
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        // Cleanup the event listener on component unmount
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <>
            {appState.apresentation.showQRCode && appState.apresentation.showQRCode.status && <div className="modalQRCodeShare">
                <div>
                    {appState.apresentation.showQRCode.text !== '' && <p className="mb-2">{appState.apresentation.showQRCode.text}</p>}
                    <QRCode className="bg-white p-3 qrcodeContainer" value={appState.apresentation.showQRCode.link !== '' ? appState.apresentation.showQRCode.link : domainConfig.aplicativos + '/?token=' + dataApplication.token} />
                </div>
            </div>}
            <div className="logoBox">
                <div className="logoBoxChildMiddle">
                    <img src={domainConfig.imageServer + "/evento/" + dataApplication.evento_id + "/" + dataApplication.customizacao.logo} alt="Logo" className="App-logo pb-0"></img>
                </div>
            </div>
            <div className="tablePresenter container text-center">
                {appState.apresentation.rankingPage.title.status && <h5 className="py-3"><TextWithLineBreaks text={appState.apresentation.rankingPage.title.value}></TextWithLineBreaks></h5>}
                <Table className="mb-0 text-center">
                    <thead>
                        <tr>
                            <th>Ranking</th>
                            {dataApplication.customizacao.form.fieldsToShow && dataApplication.customizacao.form.fieldsToShow.map((inputID, index) => {
                                const field = dataApplication.customizacao.form.fields.find(field => field.inputID === inputID);
                                if (field) {
                                    return (
                                        <th key={'th' + index}>{field.label}</th>
                                    );
                                } else {
                                    return ""
                                }
                            })}
                            <th>Pontuação</th>
                            <th>Tempo</th>
                        </tr>
                    </thead>
                    <tbody>
                        {appState.history.length > 0 ? (
                            <AnimatePresence layoutRoot layout>
                                {appState.history.map((props, index) => {
                                    if (index > 99) {
                                        return ""
                                    }
                                    return (
                                        <motion.tr
                                            key={'tr' + props.visitante_id}
                                            layout
                                            transition={{ type: "spring", stiffness: 260, damping: 20 }}>
                                            <td style={{ width: "50px" }}><span className="medalRankingPadrao text-center">{index + 1}</span></td>
                                            {dataApplication.customizacao.form.fieldsToShow && dataApplication.customizacao.form.fieldsToShow.map((inputID, index) => {
                                                const field = dataApplication.customizacao.form.fields.find(field => field.inputID === inputID);
                                                if (field && props.participantDetails && props.participantDetails[field.inputID]) {
                                                    return (
                                                        <td key={'th' + index}>{props.participantDetails[field.inputID].value}</td>
                                                    );
                                                } else {
                                                    return (
                                                        <td key={'th' + index}></td>
                                                    );
                                                }
                                            })}
                                            <td>{props.player1.value + props.player2.value}</td>
                                            <td>{props.time}</td>
                                        </motion.tr>
                                    )
                                })}
                            </AnimatePresence>
                        ) : (
                            // Display a message or a row when there are no data
                            <tr>
                                <td className="py-4" colSpan='12' style={{ textAlign: "center" }}>
                                    Não foi encontrado nenhum resultado
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
        </>
    )
}

export default MemoryGameAdmin