import React, { useRef, useState, useContext } from 'react'
import axios from 'axios'
import {
    Col,
    Form,
    Row,
} from "react-bootstrap"
import { Formik } from "formik"
import { formatInTimeZone } from "date-fns-tz";
import InputMask from "react-input-mask"
import { TextWithLineBreaks, schemaCreator } from "./components/Functions.js"
import { PageLoaderDots } from "./components/Elements"
import { useGlobalState } from './hooks/useCustomization.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faScroll } from '@fortawesome/free-solid-svg-icons';
import NotyfContext from "./contexts/NotyfContext.js";
import BaseLayout from "./components/BaseLayout"

export const Register = () => {
    const { dataApplication, dispatch } = useGlobalState();
    const formikRef = useRef(null);
    const formikActive = useRef(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const notyf = useContext(NotyfContext);

    const submitFormExternally = () => {
        if (formikRef.current && formikActive.current === false) {
            formikRef.current.submitForm();
        }
    }

    function registerUser(values, actions) {
        console.log(formikActive.current)
        if (formikActive.current === false) {
            formikActive.current = true
            let newArray = { ...values }
            let json = {}
            let dateX = new Date();
            let data_visitacao = formatInTimeZone(dateX, "UTC", "yyyy-MM-dd HH:mm");
            if (newArray) {
                if (newArray.authorization) {
                    delete newArray.authorization
                }
                Object.entries(newArray).forEach(entry => {
                    const field = dataApplication.customizacao.form.fields.find(field => field.inputID === parseInt(entry[0]));
                    json[entry[0]] = { 'label': field.label, 'value': entry[1] }
                })
            }
            let dataValues = json;
            json = JSON.stringify(json)
            console.log('request iniciado...')
            axios.post("/api/eventos/register", {
                evento_id: dataApplication.evento_id,
                json: json,
                data_visitacao: data_visitacao,
            }).then(function (response) {
                formikActive.current = false
                console.log('request finalizado com sucesso')
                if (response.data.message.encripted) {
                    console.log('request finalizado com sucesso2')
                    localStorage.setItem(dataApplication.token, JSON.stringify(response.data.message.encripted));
                    console.log('request finalizado com sucesso3')
                    dispatch({
                        type: 'setVisitante', payload: { 'visitante_id': response.data.message.visitante_id, 'data_visitacao': data_visitacao, 'json': dataValues }
                    });
                } else {
                    alert('Ocorreu um erro, tente novamente')
                    window.location.reload()
                }
            }).catch(function (error) {
                console.log('request recusado')
                actions.setSubmitting(false);
                formikActive.current = false
                notyf.open({
                    type: "danger",
                    message: 'Houve um erro, tente novamente',
                    ripple: true,
                    dismissible: true,
                });
            })
        } else {
            console.log('travou aq')
        }
    }

    if (dataApplication.customizacao.form.status) {
        return (
            <BaseLayout>
                <div className="mb-4">
                    {dataApplication.customizacao.form.title.status &&
                        <div className="col">
                            <h5 className="mb-3"><TextWithLineBreaks text={dataApplication.customizacao.form.title.value}></TextWithLineBreaks></h5>
                        </div>}
                    {dataApplication.customizacao.form.description.status &&
                        <div className="col">
                            <p className="mb-3"><TextWithLineBreaks text={dataApplication.customizacao.form.description.value}></TextWithLineBreaks></p>
                        </div>}
                </div>
                {dataApplication.customizacao.form.fields.length === 0 ? (
                    <div className="text-center">
                        <button className="btn primaryColor primaryColorText" type="submit" onClick={() => registerUser()} >
                            {dataApplication.customizacao.form.buttonText}
                        </button>
                    </div>
                ) : (
                    <>
                        <FormComponent formikRef={formikRef} registerUser={registerUser} onSubmittingStateChange={setIsSubmitting}></FormComponent>
                        <div className="text-center mt-3">
                            <button className="btn primaryColor primaryColorText" type="submit" disabled={isSubmitting} onClick={submitFormExternally} >
                                {!isSubmitting ? dataApplication.customizacao.form.buttonText : <PageLoaderDots color="#fff" width="25" padding={0} />}
                            </button>
                        </div>
                    </>
                )}
            </BaseLayout>
        )
    } else {
        return (
            <BaseLayout>
                <div className="col">
                    <h5 className="mb-3"><TextWithLineBreaks text={dataApplication.customizacao.form.disableFormTitle}></TextWithLineBreaks></h5>
                </div>
                <div className="col">
                    <p><TextWithLineBreaks text={dataApplication.customizacao.form.disableDescription}></TextWithLineBreaks></p>
                </div>
            </BaseLayout>
        )
    }
}

export const FormComponent = ({ formikRef, registerUser, onSubmittingStateChange, editValues = false, customizacao_id = 0, t = false, visitanteID = false, visitanteData = false }) => {
    const { dataApplication } = useGlobalState();

    const getInitialValues = () => {
        let values = {};

        // Add a key for the authorization message
        if (dataApplication.customizacao.form.authorizationMessage &&
            dataApplication.customizacao.form.authorizationMessage.status) {
            values.authorization = false;
        }

        dataApplication.customizacao.form.fields.forEach((field, index) => {
            //Select first value for select fields
            if (field.type === 'select') {
                values[field.inputID] = field.options[0]
            }
            //Setup defaultValue for fields to always appear in the final result, even if not empty or choosen
            if (field.defaultValue) {
                values[field.inputID] = field.defaultValue;
            }
            //Fill the fields with pre-data (App 09)
            if (editValues) {
                let data
                if (dataApplication.presenter) {
                    data = visitanteData
                } else {
                    data = dataApplication.visitante.json
                }
                Object.entries(data).forEach(([key, element]) => {
                    values[key] = element.value
                })
            }
        });
        return values;
    };

    const authorizationMessage = () => {
        return (
            <span>
                {dataApplication.customizacao.form.authorizationMessage.value} <a href={dataApplication.customizacao.form.authorizationLink} className="primaryColorT ms-1" target='_blank' rel='noreferrer'><FontAwesomeIcon icon={faScroll} size="lg" /></a>
            </span>
        )
    }

    return (
        <Formik
            validationSchema={schemaCreator(dataApplication, editValues, customizacao_id, t, visitanteID)}
            onSubmit={registerUser}
            innerRef={formikRef}
            validateOnChange={false}
            validateOnBlur={true}
            enableReinitialize
            initialValues={getInitialValues()}>
            {({
                handleSubmit,
                handleChange,
                values,
                touched,
                isValid,
                errors,
                dirty,
                isSubmitting,
                setSubmitting,
                setFieldValue,
            }) => {
                onSubmittingStateChange(isSubmitting);
                return (
                    <Form noValidate onSubmit={handleSubmit} className="text-start">
                        <Row>
                            {Object.keys(dataApplication.customizacao.form.fields).map((key) => {
                                if (dataApplication.customizacao.form.fields[key].dependencies && dataApplication.customizacao.form.fields[key].dependencies.inputID !== '') {
                                    const indexV = dataApplication.customizacao.form.fields[key].dependencies.inputID
                                    // eslint-disable-next-line eqeqeq
                                    if (values[indexV] != dataApplication.customizacao.form.fields[key].dependencies.value) {
                                        return ""
                                    }
                                }
                                const index = dataApplication.customizacao.form.fields[key].inputID.toString()
                                switch (dataApplication.customizacao.form.fields[key].type) {
                                    case 'input':
                                        let mask = false
                                        let placeholder = false
                                        switch (dataApplication.customizacao.form.fields[key].validation) {
                                            case 'telefone':
                                                mask = "(99) 9.9999-9999"
                                                placeholder = "(00) 0.0000-0000"
                                                break;
                                            case 'cpf':
                                                mask = "999.999.999-99"
                                                placeholder = "000.000.000-00"
                                                break;
                                            case 'date':
                                                mask = "99/99/9999"
                                                placeholder = "00/00/0000"
                                                break;
                                            case 'email':
                                                if (dataApplication.mode === 2 && editValues && dataApplication.presenter === false) {
                                                    return ""
                                                }
                                                break;
                                            default:
                                                break;
                                        }
                                        if (mask) {
                                            return (
                                                <Form.Group as={Col} sm={dataApplication.customizacao.form.fields[key].col} controlId={'input' + key} className='mb-3' key={'input' + key}>
                                                    <Form.Label className="mb-0">{dataApplication.customizacao.form.fields[key].label}{dataApplication.customizacao.form.fields[key].label !== `` && <p className="form-label small opacity-75">{dataApplication.customizacao.form.fields[key].subLabel}</p>}</Form.Label>
                                                    <InputMask
                                                        placeholder={placeholder}
                                                        mask={mask}
                                                        value={values[index]}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors[index]}
                                                        isValid={touched[index] && !errors[index]}
                                                    >
                                                        {(inputProps) => (
                                                            <Form.Control
                                                                {...inputProps}
                                                                type="text"
                                                                name={index}
                                                            />
                                                        )}
                                                    </InputMask>
                                                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors[index]}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            )
                                        } else {
                                            return (
                                                <Form.Group as={Col} sm={dataApplication.customizacao.form.fields[key].col} controlId={'input' + key} className='mb-3' key={'input' + key}>
                                                    <Form.Label className="mb-0">{dataApplication.customizacao.form.fields[key].label}{dataApplication.customizacao.form.fields[key].label !== `` && <p className="form-label small opacity-75">{dataApplication.customizacao.form.fields[key].subLabel}</p>}</Form.Label>
                                                    <Form.Control
                                                        placeholder={dataApplication.customizacao.form.fields[key].placeholder}
                                                        type="text"
                                                        name={index}
                                                        value={values[index]}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors[index]}
                                                        isValid={touched[index] && !errors[index]}
                                                    />
                                                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors[index]}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            )
                                        }
                                    case 'textarea':
                                        return (
                                            <Form.Group as={Col} sm={dataApplication.customizacao.form.fields[key].col} controlId={'textarea' + key} className='mb-3' key={'textarea' + key}>
                                                <Form.Label className="mb-0">{dataApplication.customizacao.form.fields[key].label}{dataApplication.customizacao.form.fields[key].label !== `` && <p className="form-label small opacity-75">{dataApplication.customizacao.form.fields[key].subLabel}</p>}</Form.Label>
                                                <Form.Control
                                                    placeholder={dataApplication.customizacao.form.fields[key].placeholder}
                                                    type="text"
                                                    as="textarea"
                                                    name={index}
                                                    value={values[index]}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors[index]}
                                                    isValid={touched[index] && !errors[index]}
                                                />
                                                <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors[index]}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        )
                                    case 'select':
                                        return (
                                            <Form.Group as={Col} sm={dataApplication.customizacao.form.fields[key].col} controlId={'select' + key} className='mb-3' key={'select' + key}>
                                                <Form.Label className="mb-0">{dataApplication.customizacao.form.fields[key].label}{dataApplication.customizacao.form.fields[key].label !== `` && <p className="form-label small opacity-75">{dataApplication.customizacao.form.fields[key].subLabel}</p>}</Form.Label>
                                                <Form.Select
                                                    name={index}
                                                    onChange={handleChange}
                                                    value={values[index]}
                                                    isInvalid={!!errors[index]}
                                                    isValid={touched[index] && !errors[index]}
                                                >
                                                    {dataApplication.customizacao.form.fields[key].options.map((key) => (
                                                        <option key={"options" + index + key} value={key}>
                                                            {key}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                                <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors[index]}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        )
                                    case 'checkbox':
                                        if (dataApplication.customizacao.form.fields[key].options && dataApplication.customizacao.form.fields[key].options.length > 0) {
                                            return (
                                                <Form.Group controlId={'checkbox' + key} className={`mb-2 mx-0 ${errors[index] ? 'is-invalid' : ''}`} key={'checkbox' + key}>
                                                    <Form.Label className="mb-0">{dataApplication.customizacao.form.fields[key].label}{dataApplication.customizacao.form.fields[key].label !== `` && <p className="form-label small opacity-75">{dataApplication.customizacao.form.fields[key].subLabel}</p>}</Form.Label>
                                                    {dataApplication.customizacao.form.fields[key].options.map((option, indexX) => (
                                                        <Form.Check
                                                            className={`col mx-0 flex-0`} // Add 'is-invalid' class if there's an error
                                                            key={'check' + index + indexX}
                                                            type="checkbox"
                                                            label={option}
                                                            name="options"
                                                            value={values[index]}
                                                            checked={(values[index] && values[index].includes(option) && dataApplication.customizacao.form.fields[key].allowMultipleChoice) || values[index] === option ? true : false}
                                                            onChange={(e) => {
                                                                if (dataApplication.customizacao.form.fields[key].allowMultipleChoice) {
                                                                    const newValues = values[index] ? [...values[index]] : [];
                                                                    if (e.target.checked) {
                                                                        newValues.push(option);
                                                                    } else {
                                                                        const idx = newValues.indexOf(option);
                                                                        if (idx > -1) newValues.splice(idx, 1);
                                                                    }
                                                                    setFieldValue(index, newValues);
                                                                } else {
                                                                    setFieldValue(
                                                                        index,
                                                                        option
                                                                    );
                                                                }
                                                            }}>
                                                        </Form.Check>
                                                    ))}
                                                    {errors[index] && <div className="invalid-feedback d-block">{errors[index]}</div>} {/* Display error message */}
                                                </Form.Group>
                                            );
                                        } else {
                                            return (
                                                <Form.Group as={Col} sm={dataApplication.customizacao.form.fields[key].col} controlId={'checkbox' + key} className='mb-2' key={'checkbox' + key}>
                                                    <Form.Check
                                                        className="checkboxFontSize"
                                                        checked={values[index] === 'Sim' ? true : false}
                                                        type="checkbox"
                                                        value={values[index]}
                                                        name={index}
                                                        label={dataApplication.customizacao.form.fields[key].label}
                                                        onChange={(e) => {
                                                            setFieldValue(
                                                                index,
                                                                values[index] === 'Sim' ? 'Não' : 'Sim'
                                                            );
                                                        }}
                                                        isInvalid={!!errors[index]}
                                                        feedback={errors[index]}
                                                        feedbackType="invalid">
                                                    </Form.Check>
                                                </Form.Group>
                                            )
                                        }
                                    default:
                                        return ""
                                }
                            })}
                            {dataApplication.customizacao.form.authorizationMessage && dataApplication.customizacao.form.authorizationMessage.status &&
                                <Form.Group
                                    as={Col}
                                    sm="12"
                                    className="mb-3"
                                    controlId="validationFormik05"
                                >
                                    <Form.Check
                                        checked={values.authorization}
                                        className="lgpd checkboxFontSize"
                                        type="checkbox"
                                        name={`authorization`}
                                        label={authorizationMessage()}
                                        onChange={(e) => {
                                            setFieldValue(
                                                `authorization`,
                                                values.authorization === true
                                                    ? false
                                                    : true
                                            );
                                        }}
                                        value={values.authorization}
                                        isInvalid={!!errors.authorization}
                                        feedback={errors.authorization}
                                        feedbackType="invalid">
                                    </Form.Check>
                                </Form.Group>
                            }
                        </Row>
                    </Form>
                )
            }
            }
        </Formik >
    )
}